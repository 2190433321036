var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sheets"},[_c('div',[_c('v-btn',{attrs:{"color":"secondary"},on:{"click":_vm.backVisitor}},[_vm._v("戻る")])],1),_c('v-divider',{staticClass:"my-4"}),_c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card',{staticClass:"ml-4",attrs:{"max-width":"600"}},[_c('v-card-title',[_vm._v("入門証発行")]),_c('v-card-text',[_c('v-text-field',{attrs:{"outlined":"","label":"受付ID","rules":[_vm.rules.required]},model:{value:(_vm.account),callback:function ($$v) {_vm.account=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"account"}}),_c('v-text-field',{attrs:{"outlined":"","label":"会社名"},model:{value:(_vm.company),callback:function ($$v) {_vm.company=$$v},expression:"company"}}),_c('v-text-field',{attrs:{"rules":[_vm.rules.required],"outlined":"","label":"氏名"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('p',[_vm._v("訪問場所")]),_c('v-row',_vm._l((_vm.rooms),function(ref){
var text = ref[0];
var id = ref[2];
return _c('v-col',{key:text,attrs:{"cols":"3"}},[_c('v-checkbox',{attrs:{"label":text,"value":id},model:{value:(_vm.room_check),callback:function ($$v) {_vm.room_check=$$v},expression:"room_check"}})],1)}),1)],1),_c('v-card-actions',[_c('v-btn',{staticClass:"mr-4",attrs:{"color":"primary","disabled":!_vm.valid},on:{"click":_vm.handlePrint}},[_vm._v("印刷")]),_c('webcam-snapshot',{model:{value:(_vm.pictureSrc),callback:function ($$v) {_vm.pictureSrc=$$v},expression:"pictureSrc"}})],1)],1)],1),_c('div',{staticClass:"sheet"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('h2',[_vm._v("入門証")])]),_c('v-col',{staticClass:"text-right"},[_c('img',{staticClass:"mt-1 mr-1",attrs:{"alt":"log","width":"100","src":require("../assets/logo.png")}})])],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"5"}},[_c('img',{attrs:{"alt":"Vue logo","src":_vm.pictureSrc}})]),_c('v-col',{attrs:{"cols":"7"}},[_c('v-row',{attrs:{"no-gutters":""}},_vm._l((_vm.rooms),function(ref){
var text = ref[0];
var colorclass = ref[1];
var id = ref[2];
return _c('v-col',{key:text,staticClass:"text-h4 py-1 text-center white--text",class:_vm.calColor(colorclass, id),attrs:{"cols":"3"},domProps:{"textContent":_vm._s(text)}})}),1)],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"8"}},[_c('p',{staticClass:"my-0 py-0 caption-line-height"},[_c('span',{staticClass:"text-6"},[_vm._v(_vm._s(_vm.company))]),_c('br'),_vm._v(" "+_vm._s(_vm.name)+"様"),_c('br'),_c('span',{staticClass:"text-6"},[_vm._v(_vm._s(_vm.nowtime())+" 入門")]),_c('br'),_c('span',{staticClass:"text-6",domProps:{"textContent":_vm._s(_vm.uuidv4())}})])]),_c('v-col',{attrs:{"cols":"2"}},[_c('div',{staticClass:"oneday red white--text text-center pt-01"},[_vm._v(" 当日"),_c('br'),_vm._v("有効 ")])]),_c('v-col',{attrs:{"cols":"2"}},[_c('img',{attrs:{"alt":"qr","src":_vm.src_qrcode,"width":"54","height":"54"}})])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }