<template>
  <div class="sheets">
    <div>
      <v-btn color="secondary" @click="backVisitor">戻る</v-btn>
    </div>
    <v-divider class="my-4"></v-divider>
    <v-form ref="form" v-model="valid">
      <v-card max-width="600" class="ml-4">
        <v-card-title>入門証発行</v-card-title>
        <v-card-text>
          <v-text-field
            v-model.trim="account"
            outlined
            label="受付ID"
            :rules="[rules.required]"
          ></v-text-field>
          <v-text-field
            v-model="company"
            outlined
            label="会社名"
          ></v-text-field>
          <v-text-field
            v-model="name"
            :rules="[rules.required]"
            outlined
            label="氏名"
          ></v-text-field>
          <p>訪問場所</p>
          <v-row>
            <v-col v-for="[text, , id] in rooms" :key="text" cols="3">
              <v-checkbox v-model="room_check" :label="text" :value="id">
              </v-checkbox>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            class="mr-4"
            :disabled="!valid"
            @click="handlePrint"
            >印刷</v-btn
          >
          <webcam-snapshot v-model="pictureSrc"></webcam-snapshot>
        </v-card-actions>
      </v-card>
    </v-form>
    <div class="sheet">
      <v-row no-gutters>
        <v-col><h2>入門証</h2></v-col>
        <v-col class="text-right"
          ><img
            alt="log"
            class="mt-1 mr-1"
            width="100"
            src="../assets/logo.png"
        /></v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="5">
          <img alt="Vue logo" :src="pictureSrc" />
        </v-col>
        <v-col cols="7"
          ><v-row no-gutters>
            <v-col
              v-for="[text, colorclass, id] in rooms"
              :key="text"
              cols="3"
              class="text-h4 py-1 text-center white--text"
              :class="calColor(colorclass, id)"
              v-text="text"
            >
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="8">
          <p class="my-0 py-0 caption-line-height">
            <span class="text-6">{{ company }}</span
            ><br />
            {{ name }}様<br />
            <span class="text-6">{{ nowtime() }} 入門</span><br />
            <span class="text-6" v-text="uuidv4()"></span>
          </p>
        </v-col>
        <v-col cols="2">
          <div class="oneday red white--text text-center pt-01">
            当日<br />有効
          </div>
        </v-col>
        <v-col cols="2">
          <img alt="qr" :src="src_qrcode" width="54" height="54" />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import axios from "axios";
import QRCode from "qrcode";
import WebcamSnapshot from "@/components/WebcamSnapshot";
export default {
  components: {
    WebcamSnapshot,
  },
  data() {
    return {
      valid: true,
      id: "",
      company: "",
      name: "",
      account: "",
      room_check: [],
      src_qrcode: "",
      pictureSrc: require("@/assets/011327.jpg"),
      rules: {
        required: (value) => !!value || "必須入力です",
      },
    };
  },
  computed: {
    rooms() {
      return [
        ["A", { "light-blue": true }, "1"],
        ["B", { yellow: true }, "2"],
        ["資", { lime: true }, "3"],
        ["倉", { brown: true, "lighten-1": true }, "4"],
        ["作", { indigo: true, "darken-4": true }, "5"],
        ["プ", { pink: true }, "6"],
        ["事", { orange: true, "darken-1": true }, "7"],
        ["メ", { cyan: true, "darken-1": true }, "8"],
      ];
    },
  },
  created() {},
  mounted() {
    this.fetchData();
  },
  methods: {
    nowtime() {
      const now = moment();
      return now.format("YYYY-MM-DD HH:mm");
    },
    uuidv4() {
      return uuidv4();
    },
    setQR() {
      QRCode.toDataURL(this.id, (err, dataurl) => {
        if (err) {
          alert(err.message);
          return;
        }
        this.src_qrcode = dataurl;
      });
    },
    calColor(color, id) {
      if (this.room_check.indexOf(id) > -1) {
        return color;
      }
      return { grey: true, "lighten-4": true };
    },
    newData() {
      if (this.$route.query.kbn !== "new") {
        return;
      }
      axios
        .get(
          "http://192.168.3.240/cova_sales/files/visitor_vue/interface/getnewid.json.asp",
          { withCredentials: true }
        )
        .then((res) => {
          if (!res.data.id) {
            return;
          }
          this.id = res.data.id;
          this.setQR();
        });
    },
    fetchData() {
      document.title = "入門証";
      if (!this.$route.query.id) {
        // this.newData();
        return;
      }
      this.id = this.$route.query.id;
      this.setQR();
      // this.getData();
    },
    getData() {
      axios
        .get(
          "http://192.168.3.240/cova_sales/files/visitor_vue/interface/get.json.asp?id=" +
            this.id,
          { withCredentials: true }
        )
        .then((res) => {
          this.company = res.data.company;
          this.name = res.data.name;
          const rooms = [...res.data.visitarea];
          let temp_rooms = [];
          this.room_check.splice(0, this.room_check.length);
          for (let i = 0; i < rooms.length; i++) {
            if (rooms[i] === "0") {
              continue;
            }
            temp_rooms.push((i + 1).toString());
          }
          this.room_check.push(...temp_rooms);
        });
    },
    calVisitarea() {
      let rtn = "";
      for (let i = 0; i < this.rooms.length; i++) {
        if (this.room_check.indexOf((i + 1).toString()) === -1) {
          rtn += "0";
          continue;
        }
        rtn += "1";
      }
      return rtn;
    },
    updateData() {
      let data = {
        date: this.nowtime(),
        accountID: this.account,
        name: this.name,
        company: this.company,
        visitarea: this.calVisitarea(),
        pic: "",
        id: this.id,
      };
      axios
        .get(
          "http://192.168.3.240/cova_sales/files/visitor_vue/interface/update01.asp",
          { withCredentials: true, params: data }
        )
        .then((res) => {
          console.log(res);
          // this.backVisitor();
        });
    },
    backVisitor() {
      window.location.href =
        "http://192.168.3.240/cova_sales/files/visitor/list.asp";
    },
    handlePrint() {
      window.print();
      // this.updateData();
    },
  },
};
</script>

<style lang="scss" scoped>
.sheet {
  page-break-after: always;
}

/* hide in print */
@media print {
  .sheets > :not(.sheet) {
    display: none;
  }
  /* mm単位で指定しているけど、vueコンポ側はpx単位なので、無理にmmにしなくてもいいかも。解像度の違いでハマるかも */
  .sheet {
    width: 91mm;
    min-height: 55mm; /* 設定しなくてもいいかも。あまり印刷画面に似せすぎると、些細な違いがバグに見えてしまう */
    padding: 0mm 0mm 0mm 2mm;
    margin: 0mm;
    background: white;
  }
  .text-6 {
    font-size: 5px;
  }
  .caption-line-height {
    line-height: 90%;
  }
  .oneday {
    width: 52px;
    height: 52px;
  }
  .pt-01 {
    padding-top: 2px;
  }
}

/* for preview */
@media screen {
  /* mm単位で指定しているけど、vueコンポ側はpx単位なので、無理にmmにしなくてもいいかも。解像度の違いでハマるかも */
  .sheet {
    width: 91mm;
    min-height: 55mm; /* 設定しなくてもいいかも。あまり印刷画面に似せすぎると、些細な違いがバグに見えてしまう */
    margin: 5mm;
    padding: 0mm 0mm 0mm 2mm;
    background: white;
    box-shadow: 0 0.5mm 2mm rgba(0, 0, 0, 0.3);
  }
  .text-6 {
    font-size: 5px;
  }
  .pt-01 {
    padding-top: 2px;
  }
  .caption-line-height {
    line-height: 90%;
  }
  .oneday {
    width: 52px;
    height: 52px;
  }
}
</style>
<style lang="scss">
/* for preview */
@media screen {
  BODY {
    background: #eee;
  }
}
</style>
