<template>
  <div>
    <v-dialog v-model="showDialog" width="700" eager>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="red lighten-2"
          dark
          v-bind="attrs"
          v-on="on"
          @click="getWebcamTrack"
        >
          Webcam Snapshot
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="headline grey lighten-2"> カメラ </v-card-title>

        <v-card-text>
          <v-row class="mt-4 ml-5">
            <div class="video-canvas-overlay mr-2">
              <video
                ref="camera"
                class="camera"
                :width="cameraSize.w"
                :height="cameraSize.h"
              ></video>
              <canvas
                ref="cameraOverlay"
                class="camera-overlay"
                :width="cameraSize.w"
                :height="cameraSize.h"
              ></canvas>
            </div>
            <canvas
              ref="picture"
              :width="pictureSize.w"
              :height="pictureSize.h"
            ></canvas>
            <audio ref="cameraSound" preload="auto">
              <source src="/sounds/camera-flash.mp3" type="audio/mp3" />
            </audio>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="takePicture"> シャッター </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "WebcamSnapshot",
  data: () => ({
    showDialog: false,
    pictureMime: "jpg",
    cameraSize: {
      h: 200,
      w: 300,
    },
    pictureSize: {
      h: 200,
      w: 300,
    },
    pictureResize: {
      h: 92,
      w: 69,
    },
    constraints: {
      audio: false,
      video: {
        width: 300,
        height: 200,
        facingMode: "user",
      },
    },
  }),
  props: ["value"],
  methods: {
    upScale(times, size) {
      const { w, h } = size;
      return { w: w * times, h: h * times };
    },
    calCooridnateOfResizePicture() {
      const { w, h } = this.pictureSize;
      const { w: resizeW, h: resizeH } = this.upScale(2, this.pictureResize);
      const x = (w - resizeW) / 2;
      const y = (h - resizeH) / 2;
      return { x, y };
    },

    drawRectangle(ctx, width, height, x, y) {
      ctx.beginPath();
      ctx.rect(x, y, width, height);
      ctx.stroke();
    },
    async takePicture() {
      try {
        await this.$refs.camera.pause();
        await this.$refs.cameraSound.play();
        await this.drawFromWebcam();
        await this.$refs.camera.play();
      } catch (e) {
        console.log(e);
      }
    },
    async getWebcamTrack() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia(
          this.constraints
        );
        if ("srcObject" in this.$refs.camera) {
          this.$refs.camera.srcObject = stream;
        } else {
          this.$refs.camera.src = window.URL.createObjectURL(stream);
        }
        this.$refs.camera.onloadedmetadata = function () {
          this.play();
        };
        const overlayCameraCanvasCtx = this.$refs.cameraOverlay.getContext(
          "2d"
        );
        const { x, y } = this.calCooridnateOfResizePicture();
        const { w, h } = this.upScale(2, this.pictureResize);
        this.drawRectangle(overlayCameraCanvasCtx, w, h, x, y);
      } catch (err) {
        console.log(err.name + ": " + err.message);
      }
    },
    async drawFromWebcam() {
      return new Promise((resolve, reject) => {
        try {
          const ctx = this.$refs.picture.getContext("2d");
          const { w, h } = this.pictureSize;
          const { w: resizeW, h: resizeH } = this.pictureResize;
          const { w: upscaledW, h: upscaledH } = this.upScale(
            2,
            this.pictureResize
          );
          ctx.drawImage(this.$refs.camera, 0, 0, w, h);
          // resize canvas
          const resizedCanvas = document.createElement("canvas");
          const resizedContext = resizedCanvas.getContext("2d");
          resizedCanvas.height = resizeH;
          resizedCanvas.width = resizeW;
          const { x, y } = this.calCooridnateOfResizePicture();
          resizedContext.drawImage(
            this.$refs.camera,
            x,
            y,
            upscaledW,
            upscaledH,
            0,
            0,
            resizeW,
            resizeH
          );
          this.$emit(
            "input",
            resizedCanvas.toDataURL(`image/${this.pictureMime}`)
          );
          resolve();
        } catch (e) {
          reject(e);
        }
      });
    },
  },
};
</script>

<style scoped>
video {
  border: 1px solid gray;
}

canvas {
  border: 1px solid gray;
}
.camera-overlay {
  border: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
}

.video-canvas-overlay {
  position: relative;
  max-height: 202px;
}
</style>